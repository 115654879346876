import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from "moment";
import CustomDatePicker from "../../../shared/components/CustomDatePicker";
import { Autocomplete, TextField } from "@mui/material";
import CustomTimePicker from "../../../shared/components/CustomTimePicker";

export default function AusenciaForm({
    onSubmitAusencia
}) {
    const ausenciaSchema = yup.object({
        fecha_inicio: yup.string().required("La fecha inicial es obligatoria"),
        fecha_fin:  yup.string().required("La fecha final es obligatoria"),
        tipo_ausencia_id: yup.number().integer().required("El tipo de ausencia es obligatorio"),
    }).required();
    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        setValue, 
        getValues,
        watch } = useForm({
            resolver: yupResolver(ausenciaSchema),
            defaultValues: {
                fecha_inicio: '',
                fecha_fin: '',
                hora_inicio: '',
                hora_fin: '',
                tipo_ausencia_id: null,
                user_id: null,
                user: null
            }
    });
    const user = JSON.parse(localStorage.getItem('user'));
    const [excluyentDays, setExcluyentDays] = useState(null);
    const [isJanuary, setIsJanuary] = useState(false);
    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });
    const fiestasNacionales = useSelector(state => {
        return state.fiestas
    });
    const users = useSelector(state => {
        return state.users.entities
    });
 
    useEffect(() => {
        if(user.rol_id !== 7 && user.rol_id !== 1) {
            setExcluyentDaysForCalendar(user.id);
            setValue('user', user);
            setValue('user_id', user.id);
        } 
    }, []);

    const setExcluyentDaysForCalendar = (userId) => {
        const user = users?.find(u => u.id === userId);
        const fiestas = fiestasNacionales.reduce((acc, curr) => {
            let start = null;

            if(curr.infinite) {
                start = moment(moment().format('YYYY') + '-' + (curr.month < 10 ? ('0' + curr.month) : curr.month) + '-' + (curr.day < 10 ? ('0' + curr.day) : curr.day));
            } else {
                start = moment(curr.year+ '-' + (curr.month < 10 ? ('0' + curr.month) : curr.month) + '-' + (curr.day < 10 ? ('0' + curr.day) : curr.day));
            }

            acc.push(start.format('YYYY-MM-DD'));

            return acc;
        }, []);
        
        const timeOffDays = user?.createdAbsences?.reduce((acc, curr) => {
            let start = moment(curr.fecha_inicio);
            let day = 0;

            while (day < curr.total_dias) {
                if (day !== 0) {
                    start = moment(start).add(1, 'day')
                }
            
                if(user.empresa_id === 1) {
                    if(start.day() !== 0 && start.day() !== 6 && !fiestas.find(f => f === start.format('YYYY-MM-DD'))) {
                        acc.push(start.format('YYYY-MM-DD'));
                        day++
                    }
                } else {
                    if(!fiestas.find(f => f === start.format('YYYY-MM-DD'))) {
                        acc.push(start.format('YYYY-MM-DD'));
                        day++
                    }
                }
                
            }

            return acc;
        }, []);

        // IF IS JANUARY, BLOCK FUTURE DATES
        if(moment().month() === 0) {
            setIsJanuary(true);
        }

        setExcluyentDays([...timeOffDays, ...fiestas]);
    }

    const handleChangeTipoAusencia = (e) => { setValue('tipo_ausencia_id', parseInt(e.target.value)) }

    const isHoliday = (date) => {
        return fiestasNacionales.find(d => {
            let curr; 

            if(d.infinite) {
                curr = moment(moment().format('YYYY') + '-' + (d.month < 10 ? ('0' + d.month) : d.month) + '-' + (d.day < 10 ? ('0' + d.day) : d.day));
            } else {
                curr = moment(d.year+ '-' + (d.month < 10 ? ('0' + d.month) : d.month) + '-' + (d.day < 10 ? ('0' + d.day) : d.day));
            }

            return curr.isSame(date);
        })
    }

    const needJustificante = (tipoAusenciaId) => {
        return tipoAusencias.find(ta => ta.id === parseInt(tipoAusenciaId))?.justificante;
    } 

    const handleChangeUser = (e, user) => { 
        if(user) {
            const userId = user.id;
            setValue('user_id',  userId);
            setExcluyentDaysForCalendar(userId);
        }
        
        setValue('user', user);
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        let ausencia;
        const start = moment(getValues('fecha_inicio'));
        const end = moment(getValues('fecha_fin'));
        const days = end.diff(start, 'days')+1;         // +1 inclusive
        let current = start;
        let totalDays = 0;

        for (let day = 0; day < days; day++) {
            if (day !== 0) {
                current = moment(current).add(1, 'day')
            }
            
            if(data.user.empresa_id === 1) {
                // EN RECOMOTOR SE CUENTAN DIAS LABORABLES
                if(current.day() !== 0 && current.day() !== 6 && !isHoliday(current)) totalDays += 1
            } else {
                // EMPRESA QUE NO SEA RECOMOTOR, SE CUENTAN DIAS NATURALES
                if(!isHoliday(current)) totalDays += 1
            }
        }

        data.fecha = moment().format('YYYY-MM-DD');
        data.fecha_inicio = start.format('YYYY-MM-DD');
        data.fecha_fin = end.format('YYYY-MM-DD');
        data.user_id = data.user_id !== null ? data.user_id : user.id;
        data.total_dias = totalDays;
        data.ausencia_justificada_hours = data.tipo_ausencia_id === 2 ? (
            data.hora_inicio.format('hh:mm') + ' - ' + data.hora_fin.format('hh:mm')
        ) : null;
        delete data.user;

        if(data?.justificante && data?.justificante?.length > 0) {
            ausencia = new FormData();

            Object.entries(data).forEach((value) => {
                if((value[0] === 'justificante') && value[1]?.length > 0) {
                    ausencia.append(value[0], value[1][0])
                } else { ausencia.append(value[0], value[1]) }
            });
        } else ausencia = data;

        onSubmitAusencia(ausencia);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column mt-2">
                        { (user.rol_id === 1 || user.rol_id === 7 || user.responsable) &&
                            <div className="form-group">
                                <label htmlFor="tipo_ausencia_id">Usuario</label>
                                { ((users && users.length > 0)) &&
                                    <Autocomplete
                                        disablePortal
                                        {...register("user", {required: true})}
                                        options={users.filter(u => u.active)}
                                        defaultValue={user}
                                        getOptionLabel={(option) =>   `${option['nombre']} ${option['apellidos']}`}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                                        onChange={handleChangeUser}
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        }
                                    />
                                }

                                <div className="form-invalid">
                                    {(errors.tipo_ausencia_id) && errors.tipo_ausencia_id?.message}
                                </div>
                            </div>
                        }

                        <div className="form-group">
                            <label htmlFor="tipo_ausencia_id">Tipo de ausencia</label>
                            { (tipoAusencias && tipoAusencias.length > 0) &&
                                <select 
                                    {...register("tipo_ausencia_id", {required: true})}
                                    defaultValue={1}
                                    onChange={handleChangeTipoAusencia}>
                                    {tipoAusencias.map(op => <option key={'tipo-ausencia-' + op.id} value={op.id}>{op.nombre}</option>) }
                                </select>
                            }

                            <div className="form-invalid">
                                {(errors.tipo_ausencia_id) && errors.tipo_ausencia_id?.message}
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column">
                            <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                                <div className="w-100 me-0 me-md-3">
                                    <CustomDatePicker 
                                        label={"Fecha inicial"}
                                        maxDateValue={isJanuary ? moment(new Date(moment().format('YYYY'), 1, 1)).format('YYYY-MM-DD') : null}
                                        minDateValue={null}
                                        disabledDates={excluyentDays}
                                        onChangeDate={(value) => setValue('fecha_inicio', value)}
                                    />
                                </div>    
                                
                                <CustomDatePicker 
                                    label={"Fecha final"}
                                    maxDateValue={isJanuary ? moment(new Date(moment().format('YYYY'), 1, 1)).format('YYYY-MM-DD') : null}
                                    disabledDates={excluyentDays}
                                    minDateValue={watch("fecha_inicio")}
                                    onChangeDate={(value) => setValue('fecha_fin', value)}
                                />
                            </div>

                            <div className="form-invalid">
                                {(errors.fecha_inicio) && errors.fecha_inicio?.message}
                                {(errors.fecha_fin) && errors.fecha_fin?.message}
                            </div>
                        </div>

                        { (watch('tipo_ausencia_id') == 2) ?
                            <div className="w-100 d-flex flex-column">
                                <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                                    <div className="form-group me-3">
                                        <CustomTimePicker 
                                            value={null}
                                            name={'hora_inicio'}
                                            placeholder={'9:00'}
                                            onChangeTime={(value) => setValue('hora_inicio', value)}
                                        />
                                    </div>
                                    
                                    <div className="form-group">
                                        <CustomTimePicker 
                                            value={null}
                                            name={'hora_fin'}
                                            placeholder={'10:30'}
                                            onChangeTime={(value) => setValue('hora_fin', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                        { (needJustificante(watch('tipo_ausencia_id'))) &&
                            <div className="w-100 d-flex flex-column">
                                <div className="form-group">
                                    <label htmlFor="justificante">Justificante</label>
                                    <input 
                                        type="file" 
                                        className="form-control" 
                                        {...register("justificante", { maxLength: 255 })} 
                                        defaultValue={''} />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}