import { useEffect } from "react";
import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faFile, faTimesCircle, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";

export default function AusenciasTable({
    ausencias,
    from,
    textNoItems,
    onApprobal,
    onDenied,
    onDownloadJustificante,
    onUploadJustificante,
    onDelete
}) {
   
    useEffect(() => {
        
    }, [ausencias]);

    return (
        <div className="w-100">
           { (ausencias?.length > 0) ?
                <div className="w-100 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>solicitante</th>
                                <th className="text-center">tipo</th>
                                <th className="text-center">fecha inicial</th>
                                <th className="text-center">fecha final</th>
                                <th className="text-center">total de días</th>
                                <th className="text-center">acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { ausencias?.map(ausencia => {
                                return (
                                    <tr key={'ausencia-pendiente-' + ausencia.id}>
                                        <td className="text-uppercase">{ausencia.createdBy.nombre} {ausencia.createdBy.apellidos}</td>
                                        <td className="text-center text-uppercase">{ausencia.tipoAusencia.nombre}</td>
                                        <td className="text-center">{moment(ausencia.fecha_inicio).format('DD-MM-YYYY')}</td>
                                        <td className="text-center">{moment(ausencia.fecha_fin).format('DD-MM-YYYY')}</td>
                                        <td className="text-center">{ausencia.total_dias}</td>
                                        <td className="text-center">
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                { (from === 'pendientes') &&
                                                    <>
                                                        <span title="Aceptar" style={{ cursor: 'pointer' }} className="me-3">
                                                            <FontAwesomeIcon icon={faCheckCircle} size="1x" className="action" onClick={() => onApprobal(ausencia.id, true)} color="#215732" />
                                                        </span> 

                                                        <span title="Rechazar" style={{ cursor: 'pointer' }}>
                                                            <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action" onClick={() => onDenied(ausencia.id)} color="#dc3545" />
                                                        </span> 
                                                    </>
                                                }
                                                { (from === 'resumen') &&
                                                    <>
                                                        { (ausencia.tipoAusencia.id === 2) ?
                                                            <div>
                                                                { (ausencia.justificante !== null) ?
                                                                    <span title="Descargar" className="ms-2" onClick={() => onDownloadJustificante(ausencia)}>
                                                                        <FontAwesomeIcon icon={faFile} size="1x" className="action" color="#215732" />
                                                                    </span>
                                                                    :
                                                                    <span title="Subir justificante" className="ms-2" onClick={() => onUploadJustificante(ausencia)}>
                                                                        <FontAwesomeIcon icon={faUpload} size="1x" className="action" color="#215732" />
                                                                    </span>
                                                                }
                                                            </div>
                                                            : null
                                                        }

                                                        <span title="Eliminar" style={{ cursor: 'pointer' }}>
                                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" onClick={() => onDelete(ausencia.id)} color="#dc3545" />
                                                        </span> 
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : 
                <div className="w-100 text-center">
                    <p>{textNoItems}</p>
                </div>
            }
        </div>
    );
}