import React, { memo, useEffect } from "react";
import { useState } from "react";
import { isEqual } from 'lodash';
import { SidebarData } from "./SidebarData";
import './Navbar.scss';
import logo from '../../../../../../images/logo_white.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserAvatar from "./components/UserAvatar";
import Notificaciones from "../Notificaciones";
import useSound from "use-sound";
import alertSfx from './sounds/alert.mp3';

const Navbar = ({
    onLogout,
}) => {
    let pathname = window.location.pathname === '/' ? 'inicio' : window.location.pathname.replace('/', '');
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [sideBar, setSidebar] = useState(false);
    const [notifBar, setNotifBar] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const count = parseInt(localStorage.getItem('notificaciones_count'));
    const [play] = useSound(alertSfx);

    const notificaciones = useSelector(state => {
        return state.notificaciones.entities
    });

    useEffect(() => {
        if(notificaciones.length > 0) {
            if(!count) {
                localStorage.setItem('notificaciones_count', notificaciones.length);
            } else {
                if(count !== notificaciones.length) {
                    localStorage.setItem('notificaciones_count', notificaciones.length);
                    if(!isArchived) play();
                    else setIsArchived(false);
                }
            }
        }
    }, [notificaciones]);
    
    const handleClickMenuItem = (path) => { navigate(path); }
    const showSidebar = (show) => { setSidebar(show); }
    const showNotifications = (show) => { setNotifBar(show); }
    const handleArchiveNotificaciones = () => { setIsArchived(true); }

    const NavItem = memo(({children}) => {
        return (
            <span>
                {children}
            </span>
        )
    }, isEqual)

    return (
        <>
            <div className="navbar-sidebar">
                <Link to="#" className="menu-bars" onMouseEnter={() => showSidebar(true)}>
                    <FontAwesomeIcon icon={faBars} size="2x" color="#215732" />
                </Link>

                <span className="notification-icon" title="Notificaciones" style={{cursor: 'pointer'}} onMouseEnter={() => showNotifications(true)}>
                    <FontAwesomeIcon className="notification-menu" icon={faBell} size="2x" color="#215732" />
                    <span className="notification-number">{notificaciones?.length}</span>
                </span>
            </div>
            <nav className={sideBar ? "nav-menu active" : "nav-menu"} onMouseLeave={() => showSidebar(false)}>
                <ul className="nav-menu-items">
                    <li className="mb-3">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="navbar-brand">
                                <img src={logo} className="brand" style={{height: '50px'}} alt="Recomotor" />
                            </div>
                            <UserAvatar style={{ cursor: 'pointer' }} onLogout={onLogout} />
                        </div>
                    </li>
                    { SidebarData.map((item, index) => {
                        if(
                            item.permission === currentUser?.rol_id || 
                            (item.permission === 0) || 
                            (currentUser?.rol_id === 1 || currentUser?.rol_id === 7)
                        ) {
                            return (
                                <NavItem key={index}>
                                    <span
                                        onClick={() => handleClickMenuItem(item.path)}
                                        className={`${item.cName} 
                                        ${item.title.toLowerCase() === pathname ? "active" : ""}`}>
                                            <li style={{ cursor: 'pointer' }}>
                                                <span>{item.title}</span>
                                            </li>
                                    </span>
                                </NavItem>
                            )
                        } else {
                            return '';
                        }
                    })}
                    
                </ul>
            </nav>

            <nav className={notifBar ? "nav-menu-notifications active" : "nav-menu-notifications"} onMouseLeave={() => showNotifications(false)}>
                <Notificaciones 
                    notificaciones={notificaciones} 
                    onArchived={handleArchiveNotificaciones}
                />
            </nav>
        </>
    );
}

export default memo(Navbar)