import { faCheck, faPen, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TipoAusenciaForm from "./components/TipoAusenciaForm";
import InfoModal from "../../../shared/components/InfoModal";
import { deleteTipoAusencia } from "../../../shared/services/tipo_ausencias";
import { toast } from "react-toastify";
import { fetchTipoAusencias } from "../../../../../../redux/read_only_data";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { getActions } from "./actions";

export default function TipoAusenciasTab() {
    const dispatch = useDispatch();
    const [currentTipo, setCurrentTipo] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });

    useEffect(() => {
        
    }, []);

    const handleAction = (action, tipoAusencia) => {
        if(action === 'edit') {
            handleEdit(tipoAusencia)
        } else  if(action === 'delete') {
            handleDelete(tipoAusencia.id)
        }
    }

    const handleNew = () => { 
        setCurrentTipo(null);
        setIsEdit(false); 
        setOpenModal(true); 
    }
    const handleEdit = (tipoAusencia) => { 
        setCurrentTipo(tipoAusencia); 
        setIsEdit(true); 
        setOpenModal(true); 
    }
   
   const handleCancel = () => {
        setCurrentTipo(null); 
        setIsEdit(false); 
        setOpenModal(false); 
   }

   const handleDelete = async(tipoAusenciaId) => {
        const response = await deleteTipoAusencia(tipoAusenciaId);

        if(response && response.success) {
            toast.success('Tipo de ausencia eliminado correctamente')
            dispatch(fetchTipoAusencias())
        }
   }
   const handleRefresh = () => { 
        setCurrentTipo(null); 
        setIsEdit(false); 
        setOpenModal(false); 
        dispatch(fetchTipoAusencias()); 
    }
  
    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-end">
                <button className="btn btn-add" onClick={() => handleNew()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
            </div>

            <div className="w-100 table-responsive mt-3">
                <table className="table">
                    <thead>
                        <tr>
                            <th>nombre</th>
                            <th className="text-center">límite de días</th>
                            <th className="text-center">auto aprovado</th>
                            <th className="text-center">color</th>
                            <th className="text-center">empresa</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        { tipoAusencias?.map(tipoAusencia => {
                            return (
                                <tr key={'tipoAusencia-' + tipoAusencia.id}>
                                    <td>{tipoAusencia.nombre}</td>
                                    <td className="text-center">{tipoAusencia.limite_dias ? tipoAusencia.limite_dias : '-'}</td>
                                    <td className="text-center">
                                        { (tipoAusencia.auto_approve) ?
                                            <FontAwesomeIcon icon={faCheck} size="1x" color="#00986C" />
                                            : 
                                            <FontAwesomeIcon icon={faTimes} size="1x" color="#dc3545" />
                                        }
                                    </td>
                                    <td className="text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="circle" style={{backgroundColor: tipoAusencia.color}}></span>
                                        </div>
                                    </td>
                                    <td className="text-center text-uppercase">{tipoAusencia?.empresa ? tipoAusencia.empresa.nombre : '-'}</td>
                                    <td className="text-center">
                                        <ActionsMenu 
                                            options={getActions()}
                                            onAction={(action) => handleAction(action, tipoAusencia)}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { (openModal) &&
                <InfoModal 
                    title={"Nuevo tipo de auséncia"}
                    content={
                        <TipoAusenciaForm
                            tipoAusencia={currentTipo}
                            isEdit={isEdit}
                            onActionDone={handleRefresh}
                        />
                    } 
                    state={openModal}
                    width={'sm'}
                    onClose={handleCancel}
                />
            }
        </div>
    )
}