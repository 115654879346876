import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomDatePicker from "../../../../../shared/components/CustomDatePicker";
import moment from "moment";
import { Switch } from "@mui/material";

export default function UserForm({user, onSubmitUser}) {
    
    const { 
        register, formState: { errors }, handleSubmit, reset, setValue, watch 
    } = useForm({
        defaultValues: {
            id: '',
            nombre: '',
            password: '',
            telefono: '',
            email: '',
            birthday: '',
            start_on: '',
            end_on: '',
            dias_totales: '',
            rol_id: 1,
            dep_id: 1,
            tipo_user_id: 2,
            jornada_completa: null,
            media_jornada: '',
            horario: '',
            empresa_id: ""
        }
    });
    const roles = useSelector(state => {
        const { roles } = state;
        return roles;
    });
    const departamentos = useSelector(state => {
        const { departamentos } = state;
        return departamentos;
    });
    const tipoUsers = useSelector(state => {
        const { tipo_users } = state;
        return tipo_users;
    });
    const empresas = useSelector(state => {
        const { empresas } = state;
        return empresas;
    });

    useEffect(() => {
        if(user) {
            reset(
                {
                    ...user, 
                    tipo_user_id: user.tipo_id
                });
        } else reset();
    }, [user]);

    const handleChangeEmpresa = (e) => {
        const value = e.target.value === "" ? "" : parseInt(e.target.value);
        setValue("empresa_id", value);
    }
    const handleChangeGenero = (e) => { setValue('genero', e.target.value); }
    const handleChangeRol = (e) => { setValue('rol_id', parseInt(e.target.value)); }
    const handleChangeDep = (e) => { setValue('dep_id', parseInt(e.target.value)); }
    const handleChangeTipoUser = (e) => { setValue('tipo_user_id', parseInt(e.target.value)); }
    const handleChangeJornada = (e) => { setValue('jornada_completa', e.target.checked); }
    const handleChangeMediaJornada = (e) => { setValue('media_jornada', e.target.value); }

    const onSubmit = (data, e) => {
        e.preventDefault();
        data.birthday = moment(data.birthday).format('YYYY-MM-DD');
        data.start_on = moment(data.start_on).format('YYYY-MM-DD');
        data.end_on = data.end_on ? moment(data.end_on).format('YYYY-MM-DD') : null;
        onSubmitUser(data);
    }

    return (
        <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <input 
                type="hidden" 
                {...register("id")} 
                defaultValue={''} />

            <div className="w-100 d-flex flex-column">
                <h5 className="mb-2">Información básica</h5>

                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group me-3">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("nombre", { required: true, maxLength: 50 })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group me-3">
                        <label htmlFor="apellidos">Apellidos</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("apellidos", { required: true, maxLength: 150 })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.apellidos?.type === 'required' && "Los apellidos son obligatorios"}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group me-3">
                        <label htmlFor="genero">Género</label>
                        <select 
                            defaultValue={user ? user.genero : ""}
                            {...register("genero")}
                            onChange={handleChangeGenero}>
                            <option value={""}>Selecciona un genero...</option>
                            <option value={"F"}>Femenino</option>
                            <option value={"M"}>Masculino</option>
                            <option value={"O"}>Otro</option>
                        </select>
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "NIF es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="nombre">DNI/NIE</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("dni", { required: true, maxLength: 20 })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "NIF es obligatorio"}
                        </div>
                    </div>
                </div>
                
                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group me-3">
                        <label htmlFor="email">Email</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("email", { required: true, maxLength: 255 })} 
                            defaultValue={user ? user.email : ''} />

                        <div className="form-invalid">
                            {errors.username?.type === 'required' && "Email es obligatorio"}
                        </div>
                    </div>

                
                    <div className="form-group">
                        <label htmlFor="telefono">Telefono:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("telefono")} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {errors.telefono?.type === 'required' && "Teléfono es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center">
                    <CustomDatePicker 
                        label={"Cumpleaños"}
                        disableFuture={true}
                        marginEnd={true}
                        minDateValue={null}
                        value={(user && user.birthday) ? moment(user.birthday) : null}
                        onChangeDate={(value) => setValue('birthday', value)}
                    />

                    <div className="form-group">
                        <div className="w-100">
                            { (user && user?.birthday) ? 
                                <small>{moment(user.birthday).format('DD-MM-YYYY')}</small>
                                : ''
                            }
                        </div>
                    </div>
                </div>

                <h5 className="my-2">Información empleado</h5>
            
                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="empresa">Empresa</label>
                        { (empresas && empresas.length > 0) &&
                            <select 
                                {...register("empresa_id", { required: true })}
                                defaultValue={user ? user.empresa_id : ''}
                                onChange={handleChangeEmpresa}>
                                <option value={""}>Ninguna</option>
                                {empresas.map(op => <option key={'empresa-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                        
                        <div className="form-invalid">
                            {errors.empresa_id?.type === 'required' && "La empresa es obligatoria"}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group">
                        <label htmlFor="rol_id">Rol</label>
                        { (roles && roles.length > 0) &&
                            <select 
                                {...register("rol_id")}
                                defaultValue={user ? user.rol_id : 1}
                                onChange={handleChangeRol}>
                                {roles.map(op => <option key={'rol-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group ms-3">
                        <label htmlFor="dep_id">Departamento</label>
                        { (departamentos && departamentos.length > 0) &&
                            <select 
                                {...register("dep_id")}
                                defaultValue={1}
                                onChange={handleChangeDep}>
                                {departamentos.map(op => <option key={'departamento-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-stretch">
                    { (user) && 
                        <div className="form-group">
                            <label htmlFor="tipo_user_id">Tipo de usuario</label>
                            { (tipoUsers && tipoUsers.length > 0) &&
                                <select 
                                    {...register("tipo_user_id")}
                                    onChange={handleChangeTipoUser}>
                                    {tipoUsers.map(op => <option key={'tipoUser-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            }

                            <div className="form-invalid">
                                {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                            </div>
                        </div>
                    }
                    
                    <div className={`form-group ${user ? 'ms-3' : ''}`}>
                        <label htmlFor="dias_totales">Dias totales de vacaciones</label>
                        <input 
                            type="number"
                            step={1} 
                            className="form-control" 
                            {...register("dias_totales", { required: true, maxLength: 50 })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.dias_totales?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-stretch">
                    <div className="flex-grow-1">
                        <CustomDatePicker 
                            label={"Primer día"}
                            disableFuture={true}
                            minDateValue={null}
                            value={(user && user?.start_on) ? moment(user.start_on) : null}
                            onChangeDate={(value) => setValue('start_on', value)}
                        />

                        { (user && user?.start_on) ? 
                            <small>{moment(user.start_on).format('DD-MM-YYYY')}</small>
                            : ''
                        }
                    </div>

                    <div className="flex-grow-1 ms-3">
                        <CustomDatePicker 
                            label={"Último día"}
                            disableFuture={true}
                            minDateValue={null}
                            value={(user && user?.end_on) ? moment(user.end_on) : null}
                            onChangeDate={(value) => setValue('end_on', value)}
                        />

                        { (user && user?.end_on) ? 
                            <small>{moment(user.end_on).format('DD-MM-YYYY')}</small>
                            : ''
                        }
                    </div>
                </div>

                <h5>Horario</h5>
                
                <div className="d-flex flex-row align-items-center">
                    <Switch 
                        color="success"
                        defaultChecked={user ? user.jornada_completa : true}
                        {...register("jornada_completa")}
                        onChange={handleChangeJornada}
                        inputProps={{ 'aria-label': 'controlled' }} /> 
                    <label>Jornada completa?</label>
                </div>

                { (watch('jornada_completa') == false) &&
                    <div className="w-100 d-flex align-items-stretch">
                        <div className="form-group me-3">
                            <label>Horario de</label>
                            <select 
                                defaultValue={''}
                                {...register("media_jornada", { required: true })}
                                onChange={handleChangeMediaJornada}>
                                <option value={""}>Selecciona...</option>
                                <option value={"morning"}>Mañana</option>
                                <option value={"afternoon"}>Tarde</option>
                            </select>
                        </div>

                        <div className="form-group me-3">
                            <label htmlFor="email">Horario</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("horario", { required: true, maxLength: 255 })} 
                                defaultValue={user ? user.horario : ''} 
                                placeholder="09:00-13:30"
                            />

                            <div className="form-invalid">
                                {errors.horario?.type === 'required' && "El horario es obligatorio"}
                            </div>
                        </div>
                    </div>
                }



                { !user &&
                    <div className="form-group">
                        <label htmlFor="email">Password</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            {...register("password", { required: true })} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {errors.username?.type === 'required' && "Password es obligatorio"}
                        </div>
                    </div>
                }
            </div>

            <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
        </form>
    )
}