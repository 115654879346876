import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer} from 'react-toastify';

import Inicio from './components/Inicio';
import Loading from "./components/shared/components/Loading";
import Config from "./components/shared/components/Config";
import Navbar from "./components/shared/components/Navbar";
import { fetchReadOnlyData } from "../../redux/read_only";
import Ajustes from "./components/Ajustes";
import Documentos from "./components/Documentos";
import LoadingBackdrop from "./components/shared/components/LoadingBackdrop";
import MiCuenta from "./components/MiCuenta";
import Dashboard from "./components/Dashboard";
import Empleados from "./components/Empleados";
import FichaEmpleado from "./components/Empleados/FichaEmpleado";

const Main = ({onLogout}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const status = useSelector(state => {
        return state.read_only.status
    });
    const loading = useSelector(state => {
        return state.loading
    })

    useEffect(() => {
        // MAKE INITIAL LOAD OF READ ONLY DATA ON REDUX STORE
        dispatch(fetchReadOnlyData(
            (user.rol_id === 1 || user.rol_id === 7) ? null
            : user.empresa_id
        ))
    }, []);

      return (
        <div className="dashboard-container">
            <Navbar onLogout={onLogout} />

            <Config />

            { (status.loading === 'pending' || status.loading === 'idle') &&
                <Loading />
            }

            { (status.loading === 'succeed' || status.loading === 'rejected') &&
                <Routes>
                    <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                    <Route path="/" exact element={<Inicio />}></Route>
                    <Route path="/ajustes" element={<Ajustes />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/documentos" element={<Documentos />}></Route>
                    <Route path="/mi-cuenta" element={<MiCuenta />}></Route>
                    <Route path="/empleados" element={<Empleados />}></Route>
                    <Route path="/empleados/ficha/:userId" element={<FichaEmpleado />}></Route>
                </Routes>
            }

            { loading && <LoadingBackdrop /> }

            <ToastContainer
                containerId="dashboard"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </div> 
    );
}

/**

 */

export default Main