import { useEffect } from "react";
import React from "react";
import Admin from "./Admin";
import Empleado from "./Empleado";

export default function Documentos() {
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => { 
    }, []);

    return (
        <div className="content">
            { (user.rol_id === 7 || user.rol_id === 1) ?
                <Admin />
                :
                <Empleado />
            }
        </div>
    );
}