import { useState, useEffect } from "react";
import React from "react";
import Calendar from "./components/Calendar";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/components/Loading";
import { fetchUsers } from "../../../../redux/users";
import AusenciaForm from "./components/AusenciaForm";
import moment from "moment";
import InfoModal from "../shared/components/InfoModal";
import { exportAusencias, nuevaAusencia } from "../shared/services/ausencias";
import { toast } from "react-toastify";
import Header from "./components/Header";
import PendientesList from "./components/PendientesList";
import Resumen from "./components/Resumen";
import { setErrorMessage } from "../shared/helpers/functionalities";
import ExportModal from "./components/ExportModal";
import { createRegistroHorariosExcel } from "../shared/helpers/registroHorarioExcel";
import { createAusenciasExcel } from "../shared/helpers/ausenciasExcel";
import { getRegistroHorariosUsers } from "../shared/services/registros_horarios";

export default function Inicio() {
    const users = useSelector(state => {
        return state.users.entities
    });
    const usersStatus = useSelector(state => {
        return state.users.status
    });
    const fiestasNacionales = useSelector(state => {
        return state.fiestas
    });
    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const [departamento, setDepartamento] = useState(-1);
    const [usuario, setUsuario] = useState({id: -1, nombre: 'Todos'});
    const [activeDate, setActiveDate] = useState(moment());
    const [currentUsers, setCurrentUsers] = useState(users);
    const [openNew, setOpenNew] = useState(false);
    const [openPendientes, setOpenPendientes] = useState(false);
    const [openResumen, setOpenResumen] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    
    useEffect(() => {
        setCurrentUsers(users);
        const dep_id = (user.rol_id === 1 || user.rol_id === 7 || user.tipo_id === 1) ? null : user.dep_id;
            
        if(dep_id) { handleChangeDepartamento({target: {value: dep_id }}) }
    }, [users]);

    const handleRefresh = () => {
        dispatch(fetchUsers(
            (user.rol_id === 1 || user.rol_id === 7) ? null : user.empresa_id,
            (user.rol_id === 1 || user.rol_id === 7) ? null : user.dep_id
        ));
    }

    const handleChangeMonth = (month) => { setActiveDate(moment(month)); } 
    const handleChangeDepartamento = (e) => {
        const value = parseInt(e.target.value);

        setDepartamento(value);
        
        if(value === -1) { 
            setCurrentUsers(users); 
        } else { 
            const filteredUsers = users.filter(u => u.dep_id === value);
            setCurrentUsers(filteredUsers);
        }
    }

    const handleChangeUser = (e, user) => {
        if((user && user.id !== -1)) {
            setUsuario(user);
            const filteredUsers = users.filter(u => u.id === user.id);
            setCurrentUsers(filteredUsers);
        } else {
            if(departamento === -1) {
                const activeUsers = users.filter(u => u.active)
                setCurrentUsers(activeUsers);
                setUsuario({id: -1, nombre: 'Todos'});
            } else {
                const filteredUsers = users.filter(u => u.dep_id === departamento && u.active == true);
            setCurrentUsers(filteredUsers);
            }
        }
    }

    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);
    const handleOpenPendientes = () => setOpenPendientes(true);
    const handleClosePendientes = () => setOpenPendientes(false);
    const handleOpenResumen = () => setOpenResumen(true);
    const handleCloseResumen = () => setOpenResumen(false);
    const handleOpenExport = () => { setOpenExport(true); }
    const handleCloseExport = () => { setOpenExport(false); }

    const handleNewAusencia = async(ausencia) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await nuevaAusencia(ausencia)
        .catch((error) => {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            setOpenNew(false);
            toast.success('Ausencia creada correctamente!');
            dispatch(fetchUsers(
                (user.rol_id === 1 || user.rol_id === 7) ? null : user.empresa_id,
                (user.rol_id === 1 || user.rol_id === 7) ? null : user.dep_id
            ));
        }
    }

    const handleExportAusencias = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await exportAusencias(data)
        .catch((error) => {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            const excel = await createAusenciasExcel(
                response.ausencias,
                tipoAusencias
            )

            let fileName = '';
            toast.success('Exportación hecha correctamente!')
          
            const url = window.URL.createObjectURL(new Blob([excel]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';

            if(data.user_id !== -1) {
                const user = users.find(u => u.id === data.user_id);
                const userName = user.nombre?.replace(' ', '_')?.toUpperCase();
                fileName = 'Ausencias_' + userName + '_' + moment(data.startDate).format('DD-MM-YY') + '_' + moment(data.endDate).format('DD-MM-YY') + '.xlsx';
            } else {
                fileName = 'Ausencias_' + moment(data.startDate).format('DD-MM-YY') + '_' + moment(data.endDate).format('DD-MM-YY') + '.xlsx';
            }
    
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    } 

    const handleExportRegistrosHorarios = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await getRegistroHorariosUsers(data)
        .catch((error) => {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            const excel = await createRegistroHorariosExcel(
                data.startDate,
                data.endDate,
                data.user_id,
                response.ausencias,
                response.users,
                fiestasNacionales,
                tipoAusencias
            )

            let fileName = '';
            toast.success('Exportación hecha correctamente!')
            const url = window.URL.createObjectURL(new Blob([excel]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';

            const user = users.find(u => u.id === data.user_id);
            const userName = user ? user.nombre?.replace(' ', '_')?.toUpperCase() : '';
            fileName = 'Registro_Horario_' + userName + '_' + moment(data.startDate).format('DD-MM-YY') + '_' + moment(data.endDate).format('DD-MM-YY') + '.xlsx';
    
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    if(usersStatus.loading === 'pending' || usersStatus.loading === 'idle') {
        return ( <Loading /> );
    }

    if(usersStatus.loading === 'succeed' || usersStatus.loading === 'rejected') {
        return (
            <div className="content">
                <Header 
                    user={usuario}
                    users={currentUsers.filter(u => u.active)}
                    departamento={departamento}
                    activeDate={activeDate}
                    onChangeDepartamento={handleChangeDepartamento}
                    onChangeUser={handleChangeUser}
                    onChangeMonth={handleChangeMonth}
                    onOpenNew={handleOpenNew}
                    onOpenPendientes={handleOpenPendientes}
                    onOpenResumen={handleOpenResumen}
                    onOpenExport={handleOpenExport}
                />

                <Calendar 
                    users={currentUsers.filter(u => u.active)} 
                    activeDate={activeDate}  
                />

                { (openNew) &&
                    <InfoModal
                        title={"Nueva auséncia"}
                        content={
                            <AusenciaForm
                                onSubmitAusencia={handleNewAusencia}
                            />
                         } 
                        state={openNew}
                        width={'sm'}
                        onClose={handleCloseNew}
                    />
                }

                { (openPendientes) &&
                    <InfoModal
                        title={"Ausencias pendientes"}
                        content={
                            <PendientesList
                                onConfirmAction={handleRefresh}
                            />
                         } 
                        state={openPendientes}
                        width={'md'}
                        onClose={handleClosePendientes}
                    />
                }

                { (openResumen) &&
                    <InfoModal
                        title={"Resumen de ausencias"}
                        content={
                            <Resumen
                                start={moment(activeDate).startOf('month').format('YYYY-MM-DD')}
                                end={moment(activeDate).endOf('month').format('YYYY-MM-DD')}
                            />
                         } 
                        state={openResumen}
                        width={'md'}
                        onClose={handleCloseResumen}
                    />
                }

                { (openExport) &&
                    <InfoModal
                        title={"Exportaciones"}
                        content={
                            <ExportModal 
                                onExportAusencias={handleExportAusencias}
                                onExportRegistroHorarios={handleExportRegistrosHorarios}
                            />
                        } 
                        state={openExport}
                        width={'sm'}
                        onClose={handleCloseExport}
                    />
                }
            </div>
        );
    }
}