import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getAusencias = async (approved = null) => {
    const aprobado = approved !== null ? '?approved=' + approved : '';
    return await API.get('/ausencias' + aprobado).then(res => res.data);
}

export const getAusenciasMensuales = async (startDate, endDate) => {
    const range = '?startDate=' + startDate + '&endDate=' + endDate;
    return await API.get('/ausencias/mensuales' + range).then(res => res.data);
}

export const getJustificanteAusencia = async (ausenciaId) => {
    return await API.get('/ausencias/' + ausenciaId + '/justificante', {responseType: 'blob'}).then(res => res.data);
}

export const nuevaAusencia = async (ausencia) => {
    return await API.post('/ausencias', ausencia, (ausencia instanceof FormData) ? headers : null).then(res => res.data);
}

export const uploadJustificante = async (ausencia, ausenciaId) => {
    return await API.post('/ausencias/' + ausenciaId + '/justificante', ausencia, headers).then(res => res.data);
}

export const exportAusencias = async (filters) => {
    return await API.post('/ausencias/excel', filters).then(res => res.data);
}

export const aprobarAusencia = async (ausencia) => {
    return await API.put('/ausencias/' + ausencia.id, ausencia).then(res => res.data);
}

export const removeAusencia = async (ausenciaId) => {
    return await API.delete('/ausencias/' + ausenciaId).then(res => res.data);
}