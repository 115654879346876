import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import UserMetrics from './components/UserMetrics';
import GeneralMetrics from './components/GeneralMetrics';
import DateSelector from '../shared/components/DateSelector';
import { fetchGeneralMetrics, fetchUserMetrics } from '../../../../redux/dashboard';
import './dashboard.scss';

export default function Dashboard() {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [view, setView] = useState('mes');
    const [currentUsers, setCurrentUsers] = useState(null);
    const dashboard = useSelector(state => {
        return state.dashboard.entity
    });
    const users = useSelector(state => {
        return state.users.entities
    });

    useEffect(() => {
        if(dashboard.generalMetrics) {
            const usersLabels = dashboard.generalMetrics.holidays_chart_data.datasets.map(d => {
                return d.label;
            })

            const usersAux = users.reduce((acc, curr) => {
                if(usersLabels.find(r => r === curr.nombre?.toUpperCase())) {
                    acc.push(curr);
                    return acc;
                }
                return acc;
            }, [])

            setCurrentUsers(usersAux);
        } else dispatch(fetchGeneralMetrics(startDate, endDate, view));
    }, [dashboard]);


    const handleChangeUser = (e) => {
        if(parseInt(e.target.value) === -1) {
            dispatch(fetchGeneralMetrics(startDate, endDate, view));
            setCurrentUser(null);
        } else {
            dispatch(fetchUserMetrics(e.target.value, startDate, endDate, view));
            setCurrentUser(e.target.value);
        }
    }

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        if(!currentUser) dispatch(fetchGeneralMetrics(startDate, endDate, view));
        else dispatch(fetchUserMetrics(currentUser, startDate, endDate, view));
    }
    

    return (
        <div className='content'>
            <div className='w-100 d-flex flex-column align-items-center dashboard'> 
                <div className='w-100 mt-4 summary'>
                    <h1>Información por usuario</h1>

                    <div className='w-100 d-flex align-items-center position-relative pt-5 mb-4'>
                        { ((currentUsers && currentUsers.length > 0)) &&
                            <select 
                                id="responsable"
                                defaultValue={currentUser ? currentUser : -1}
                                onChange={(e) => handleChangeUser(e)}>
                                <option key={'user-' + 0} value={-1}>Todos</option>
                                {currentUsers.map(op => <option key={'user-' + op.id} value={op.id}>{op.nombre} {op.apellidos}</option>)}
                            </select>
                        }

                        <DateSelector onSelectedDate={handleDateInterval} /> 
                    </div>

                    { (!currentUser && dashboard.generalMetrics) &&  
                        <GeneralMetrics 
                            view={view}
                            data={dashboard.generalMetrics} />
                    }

                    { (currentUser && dashboard.userMetrics) && 
                        <UserMetrics 
                            view={view}
                            currentUser={dashboard.userMetrics} />
                    }
                </div>
            </div>
        </div>
    )
}
