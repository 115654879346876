
import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getUsers = async (empresaId = null, depId = null) => {
    const empresa = empresaId !== null ? ('?empresa_id=' + empresaId) : ''
    const departamentos = depId !== null ? ('&dep_id=' + depId) : '';
    return await API.get('/user' + empresa + departamentos).then(res => res.data);
}

export const getUserInfo = async (userId) => {
    return await API.get('/user/' + userId).then(res => res.data);
}

export const getCvUser = async (userId) => {
    return await API.get('/user/' + userId + '/cv', {responseType: 'blob'}).then(res => res.data);
}

export const newUser = async (user) => {
    return await API.post('/user', user).then(res => res.data);
}

export const updateUser = async (user) => {
    return await API.put('/user/' + (user instanceof FormData ? user.get('id') : user.id), user, (user instanceof FormData) ? headers : null).then(res => res.data);
}

export const enableDisableUser = async (userId) => {
    return await API.put('/user/' + userId + '/enable').then(res => res.data);
}

export const changePassword = async (userId, password) => {
    return await API.post(`/user/${userId}/password`, {password}).then(res => res.data)
  }